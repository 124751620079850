import axios from 'axios'
import { BackendResource } from './resources'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL!
const API_KEY = process.env.REACT_APP_API_KEY!

/**
 * Returns an instance of the P4P API client.
 */
export function createP4PApiClient() {
  async function getUser() {
    return await get('api/user')
  }

  async function login(data: object) {
    return await post('api/login', data)
  }

  async function socialLogin(data: object) {
    return await post('api/social-login', data)
  }

  async function updateAccountInfo(data: object) {
    return await post('api/update-account-info', data)
  }

  async function logout() {
    return await get('api/logout')
  }

  async function forgotPassword(data: object) {
    return await post('api/forgot-password', data)
  }

  async function resetPassword(data: object) {
    return await post('api/reset-password', data)
  }

  async function getInTouch(data: object) {
    return await post('api/get-in-touch', data)
  }

  async function calculateParentQuizResults(data: object) {
    return await post('api/parent-quiz-results', data)
  }

  async function calculateKidQuizResults(data: object) {
    return await post('api/kid-quiz-results', data)
  }

  async function getParentQuizResults() {
    return await get('api/parent-quiz-results')
  }

  async function getKidQuizResults() {
    return await get('api/kid-quiz-results')
  }

  async function getUserDashboardData() {
    return await get('api/user-dashboard-data')
  }

  async function getCustomerDetails() {
    return await get('api/get-customer-details')
  }

  async function subscriptionCreateAccount(data: object) {
    return await post('api/subscription-create-account', data)
  }

  async function getAllSubscriptionOptions() {
    return await get('api/get-all-subscription-options')
  }

  async function getClientSecret(data: object) {
    return await post('api/get-client-secret', data)
  }

  async function validateCouponCode(data: object) {
    return await post('api/validate-coupon-code', data)
  }

  async function isUserAdmin() {
    return await get('api/is-user-admin')
  }

  async function sendTestPostcard(data: object) {
    return await post('api/send-test-postcard', data)
  }

  async function sendIntroCycle(data: object) {
    return await post('api/send-intro-cycle', data)
  }

  async function get(resource: BackendResource) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-API-Key': API_KEY
      }

      if (localStorage.getItem('token')) {
        headers['Authorization'] = `Bearer ${
          localStorage.getItem('token') || ''
        }`
      }

      return await axios.get(`${BACKEND_URL}/${resource}`, {
        headers: headers
      })
    } catch (e) {
      if (e.response.status === 401) {
        localStorage.clear()

        if (window.location.pathname === '/admin') {
          window.location.replace('/')
        }
      }

      return e.response
    }
  }

  async function post(resource: BackendResource, data: object) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-API-Key': API_KEY
      }

      const csrfToken = document.head.querySelector(
        'meta[name="csrf-token"]'
      )?.['content']
      headers['X-CSRF-Token'] = csrfToken

      if (localStorage.getItem('token')) {
        headers['Authorization'] = `Bearer ${
          localStorage.getItem('token') || ''
        }`
      }

      return await axios.post(`${BACKEND_URL}/${resource}`, data, {
        headers: headers
      })
    } catch (e) {
      if (e.status === 401) {
        window.location.replace('/')
        localStorage.clear()
      }

      return e.response
    }
  }

  return {
    getUser,
    login,
    socialLogin,
    updateAccountInfo,
    logout,
    forgotPassword,
    resetPassword,
    getInTouch,
    calculateParentQuizResults,
    calculateKidQuizResults,
    getParentQuizResults,
    getKidQuizResults,
    getUserDashboardData,
    getCustomerDetails,
    subscriptionCreateAccount,
    getAllSubscriptionOptions,
    getClientSecret,
    validateCouponCode,
    isUserAdmin,
    sendTestPostcard,
    sendIntroCycle
  }
}

export type P4PApi = ReturnType<typeof createP4PApiClient>
