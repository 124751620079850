import { useFormik } from 'formik'
import { useState } from 'react'
import { SubscriptionCreateAccountData } from '../core/types'
import { grades } from 'core/grades'
import { Link } from 'react-router-dom'
import { useAtomValue, useSetAtom } from 'jotai'
import { p4pApiAtom } from 'data'
import { toast } from 'react-toastify'
import { refetchCurrentUserAtom } from 'pages/auth/core/atoms'
import { imgRightArrowBlack } from 'core/images'

type Props = {
  handleShowLoginModalClick: () => void
}

export function SubscriptionCreateAccount({
  handleShowLoginModalClick
}: Props) {
  const api = useAtomValue(p4pApiAtom)

  const refetchCurrentUser = useSetAtom(refetchCurrentUserAtom)

  const [createAccountData, setCreateAccountData] =
    useState<SubscriptionCreateAccountData>({
      grades: []
    })
  const [creatingAccount, setCreatingAccount] = useState(false)

  const formik = useFormik({
    initialValues: createAccountData,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      await formik.validateForm(values)

      try {
        setCreatingAccount(true)

        const response = await api.subscriptionCreateAccount(createAccountData)

        if (response.status !== 200) {
          if (response?.data?.error) {
            const errorMessage = Object.keys(response?.data?.error).map((key) =>
              response?.data?.error[key].map((message) => <p>{message}</p>)
            )
            toast.error(<>{errorMessage}</>)
            return
          }

          toast.error('Error while processing request')
          return
        }

        localStorage.setItem('token', response.data.token)

        await refetchCurrentUser()

        setCreatingAccount(false)
      } catch (e) {
        toast.error('Error while processing request')
        console.error(e)
        setCreatingAccount(false)
      }
    },
    validate: () => {
      const error = {}

      if (!createAccountData.fname || createAccountData.fname.length === 0) {
        error['fname'] = 'Please provide a first name'
      }

      if (!createAccountData.lname || createAccountData.lname.length === 0) {
        error['lname'] = 'Please provide a last name'
      }

      if (!createAccountData.email || createAccountData.email.length === 0) {
        error['email'] = 'Please provide an email'
      }

      if (
        !createAccountData.password ||
        createAccountData.password.length === 0
      ) {
        error['password'] = 'Please provide a password'
      }

      if (
        !createAccountData.confirm_password ||
        createAccountData.confirm_password.length === 0
      ) {
        error['confirm_password'] = 'Please confirm your password'
      }

      if (createAccountData.grades.length === 0) {
        error['grades'] = 'Please select at least one grade'
      }

      return error
    }
  })

  function handleFormDataChanged(event) {
    let newData = createAccountData

    switch (event.target.name) {
      case 'fname':
        newData = { ...newData, fname: event.target.value }
        break

      case 'lname':
        newData = { ...newData, lname: event.target.value }
        break

      case 'email':
        newData = { ...newData, email: event.target.value }
        break

      case 'password':
        newData = { ...newData, password: event.target.value }
        break

      case 'confirm_password':
        newData = { ...newData, confirm_password: event.target.value }
        break

      default:
        if (event.target.name.toString().includes('=')) {
          const grade = parseInt(event.target.name.split('=')[1])

          const grades = newData.grades

          const gradesIndex = grades.findIndex((item) => item === grade)

          if (gradesIndex >= 0) {
            grades.splice(gradesIndex, 1)
          } else {
            grades.push(grade)
          }

          grades.sort((a, b) => (a > b ? 1 : -1))

          newData = { ...newData, grades: grades }
        }
    }

    setCreateAccountData(newData)

    formik.handleChange(event)
  }

  return (
    <form className="SubscriptionCreateAccount" onSubmit={formik.handleSubmit}>
      <h1>Create account</h1>
      <p>
        Already have an account?{' '}
        <button type="button" onClick={handleShowLoginModalClick}>
          Log in
        </button>
      </p>
      <h3>What grade is your child in?</h3>

      <div className={`gradeList ${formik.errors.grades ? 'error' : ''}`}>
        {grades.map((grade) =>
          grade.items.map((gradeItem) => {
            const isChecked = createAccountData.grades.includes(gradeItem.key)

            return (
              <label key={gradeItem.key}>
                <input
                  type="checkbox"
                  name={`grade=${gradeItem.key}`}
                  onChange={handleFormDataChanged}
                  checked={isChecked}
                />
                <span className="checkmark"></span>
                <span className="grade">{gradeItem.label}</span>
              </label>
            )
          })
        )}
      </div>
      {formik.errors.grades && (
        <span className="errorMessage">{formik.errors.grades}</span>
      )}

      <h3 className="userDataTitle">Please enter your info below</h3>

      <input
        className={`userData ${formik.errors.fname ? 'error' : ''}`}
        type="text"
        name="fname"
        placeholder="First Name"
        onChange={handleFormDataChanged}
      />
      {formik.errors.fname && (
        <span className="errorMessage">{formik.errors.fname}</span>
      )}

      <input
        className={`userData ${formik.errors.lname ? 'error' : ''}`}
        type="text"
        name="lname"
        placeholder="Last Name"
        onChange={handleFormDataChanged}
      />
      {formik.errors.lname && (
        <span className="errorMessage">{formik.errors.lname}</span>
      )}

      <input
        className={`userData ${formik.errors.email ? 'error' : ''}`}
        type="email"
        name="email"
        placeholder="Email Address"
        onChange={handleFormDataChanged}
      />
      {formik.errors.email && (
        <span className="errorMessage">{formik.errors.email}</span>
      )}

      <input
        className={`userData ${formik.errors.password ? 'error' : ''}`}
        id="password"
        name="password"
        placeholder="Password"
        type="password"
        onChange={handleFormDataChanged}
      />
      {formik.errors.password && (
        <span className="errorMessage">{formik.errors.password}</span>
      )}

      <input
        className={`userData ${formik.errors.confirm_password ? 'error' : ''}`}
        id="confirm_password"
        name="confirm_password"
        placeholder="Confirm password"
        type="password"
        onChange={handleFormDataChanged}
      />
      {formik.errors.confirm_password && (
        <span className="errorMessage">{formik.errors.confirm_password}</span>
      )}

      <div className="submitContainer">
        <button type="submit" className="submit" disabled={creatingAccount}>
          Create your account!
          <img
            src={imgRightArrowBlack}
            className="img-fluid"
            alt="Arrow right"
          />
        </button>
        {creatingAccount && (
          <div
            className="spinner-border spinner-border-sm ms-2 loading"
            role="status"
          />
        )}
      </div>

      <p className="consent">
        By continuing, you agree to Postcards for Parents'{' '}
        <Link style={{ color: 'blue' }} to={'/terms-of-use'}>
          Terms & Conditions
        </Link>{' '}
        and{' '}
        <Link style={{ color: 'blue' }} to={'/privacy-policy'}>
          Privacy Policy
        </Link>
      </p>
    </form>
  )
}
